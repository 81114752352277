
export default {
  name: 'ElementsLoader',
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default() {
        return this.$t('misc.loading')
      }
    },
    size: {
      type: String,
      default: '50px'
    },
    hideText: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    styles() {
      return {
        width: this.size,
        height: this.size
      }
    }
  }
}
