import en from 'vee-validate/dist/locale/en.json'
export default (context) => {
  return new Promise(function (resolve) {
    resolve({
      meta: {
        title: 'Tickets for concerts, shows and other events'
      },
      validation: {
        ...en.messages,
        phone: 'This field should be valid phone number',
        password: "Passwords don't match",
        payment_method: 'Please choose the desired payment method'
      },
      errors: {
        basket: {
          ticket_cant_be_reserved: 'This ticket is already reserved by someone'
        },
        checkout: {
          email_invalid: 'Email is invalid',
          email_domain_invalid: 'Email domain is invalid',
          surname_too_short: 'Surname is too short',
          name_too_short: 'Name is too short'
        },
        promo_code_not_found: 'Failed to apply promocode'
      },
      cookies: {
        content: `This website uses cookies that are absolutely necessary for it to function properly. To learn more, please review our cookie usage policy. If the necessary cookies are not used, there is a possibility that some services and website functions may not work. To improve user experience and service quality, the website uses the following types of cookies: analytical cookies, which analyze and enhance the website's performance, social media cookies, which allow sharing content on social networks, and marketing cookies.`,
        title: `By continuing to use the website, please indicate whether you agree to the use of cookies`,
        required: 'Necessary Cookies',
        statistics: 'Statistics: Statistical cookies',
        social: `Social Media Cookies (Check if you want to share page content on social networks) `,
        marketing: 'Marketing Cookies'
      },
      password: {
        weak: 'Weak',
        fair: 'Fair',
        good: 'Good',
        strong: 'Strong',
        excellent: 'Excellent'
      },
      menuTitles: {
        concerts: 'Concerts',
        theater: 'Theater',
        festivals: 'Festivals'
      },
      buttons: {
        save: 'Save',
        change_password: 'Change password',
        unsubscribe: 'Unsubscribe',
        sign_in: 'Sign in',
        sign_up: 'Sign up',
        load_more: 'Load more',
        retry_purchase: 'Retry purchase',
        fix_world: 'Try to fix the world',
        float_away: 'Float away',
        apply: 'Apply',
        clear: 'Clear',
        read_more: 'Read more',
        tickets_and_more: 'Tickets & More',
        enter: 'Enter'
      },
      modal: {
        cookies_updated: 'Your cookies updated!',
        request_submitted: 'Your request was successfully submitted',
        password_changed: 'Password changed!',
        personal_data_updated: 'Personal data was updated!'
      },
      search: {
        search: 'Search',
        placeholder: 'Search for events, venues',
        view_all_results: 'Show all',
        no_results_found: 'No results found'
      },
      headings: {
        spam: 'Notifications',
        presale_code: 'Presale code...',
        about: 'About',
        success: 'Success!',
        sign_in: 'Sign in',
        sign_in_with: 'Sign in with',
        sign_up_with: 'Sign up with',
        password_change: 'Password change',
        recommended: 'Recommended',
        related: 'Related events',
        latest: 'Latest',
        news: 'News',
        rescheduled: 'Rescheduled',
        cancelled: 'Cancelled',
        username: 'Username',
        old_password: 'Old password',
        new_password: 'New password',
        repeat_password: 'Repeat password',
        password: 'Password',
        forgotpass: 'Forgot password',
        iagree: 'I agree',
        name: 'Name',
        surname: 'Surname',
        email: 'E-mail',
        phone: 'Phone',
        or: 'or',
        newuser: 'New user',
        userhaveaccount: 'Already have an account',
        customersupport: 'Customer support',
        payment: 'Payment',
        unsubscribe: 'Unsubscribe',
        unsubscribe_subtitile:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        reset_password: 'Password reset',
        reset_password_l2:
          'Fear not. We’ll email you instructions to reset your password.',
        categories: 'Categories',
        events: 'Events',
        logging_out: 'Logging out',
        payment_failed: 'Payment failed',
        payment_success: 'Payment is successful!',
        search_results_for: 'Search results for'
      },
      badges: {
        soldout: 'Soldout',
        premiere: 'Premiere'
      },
      navigation: {
        information: 'Information',
        faq: 'FAQ',
        terms: 'Ticket purchase rules',
        tickets: 'Tickets',
        etickets: 'E-tickets',
        payments: 'Payments',
        privacy: 'Privacy policy',
        cookies: 'Cookies usage policy',
        contacts: 'Contacts',
        news: 'News',
        partners: 'Partners',
        about: 'About us',
        authorize: 'Sign in',
        register: 'Register',
        userprofile: 'Profile',
        pay: 'Pay',
        apply: 'Apply',
        logout: 'Exit',
        usertickets: 'My tickets',
        backToTickets: 'Back to tickets',
        backToEventInfo: 'Back to event info',
        back: 'Back',
        gotopayment: 'Go to payment',
        retry_trans: 'Retry transaction',
        closeCart: 'Close cart',
        remove: 'Remove',
        insurance: 'Insurance terms',
        publiccontract: 'General terms of the ticket sales contract'
      },
      labels: {
        company: 'Company name',
        email: 'E-mail',
        confirm_email: 'Confirm E-mail',
        legalAddress: 'Legal address',
        name: 'Name',
        optional: '(optional)',
        phone: 'Phone',
        regnr: 'Reg. nr.',
        required: '(required)',
        surname: 'Surname',
        vat: 'VAT No.'
      },
      misc: {
        support_email: 'Support email',
        tickets_left: 'Tickets available',
        choose_time: 'Choose time',
        select_your_branch: 'Select Your branch',
        back_to_all_payment_methods: 'Back to all payment methods',
        accept: 'Accept',
        additionalOptions: 'Additional settings',
        langselector: 'English',
        withuseragreement: 'terms and conditions',
        price_from: 'from',
        from: 'from',
        to: 'to',
        price: 'price',
        soldout: 'soldout',
        discount: 'Discount',
        download: 'Download',
        event_name: 'Event name',
        loading: 'Loading',
        processing_order: 'Processing order',
        load_more: 'Load more',
        more_info: 'More info',
        selectDiscount: 'Select discount',
        sevice_fee_and_vat: 'Service fee (incl. VAT)',
        fixed_service_fee: 'Venue service fee (incl. VAT)',
        ticket_details: 'Ticket details',
        ticket_id: 'Ticket ID',
        ticket_no: 'Ticket No',
        ticket_type: 'Ticket type',
        tickets: 'tickets | ticket | tickets',
        total: 'Total',
        buy_ticket: 'Buy ticket',
        add_to_cart: 'Add to cart',
        standticket: 'Entrance tickets',
        seatticket: 'Seat tickets',
        close: 'Close',
        required_fields: 'Required fields',
        at: 'at',
        no_tickets: 'No tickets',
        order_id: 'Order id',
        purchased: 'Purchased',
        download_invoice: 'Download invoice',
        download_pdf: 'Download PDF',
        not_available: 'not available',
        work_hours: 'M. - S.',
        doors: 'Doors',
        start: 'Start',
        age_restriction: 'Age restriction',
        tags: 'Tags',
        by: 'by',
        organised_by: 'organised by',
        sector: 'SEC'
      },
      ticketselector: {
        SALES_NOT_STARTED: {
          title: 'The sale has not started yet',
          subtitle: ''
        },
        SALES_ENDED: {
          title: 'Sales ended',
          subtitle: ''
        },
        AVAILABLE: {
          title: '',
          subtitle: ''
        },
        SOMEONE_IS_BUYING: {
          title: 'Someone is buying last tickets',
          subtitle: 'Maybe some tickets will be released in few minutes'
        },
        OWN: {
          title: '',
          subtitle: ''
        },
        SOLDOUT: {
          title: 'SOLDOUT',
          subtitle: ''
        }
      },
      text: {
        password_reset_email_sent:
          'Instructions for changing the password have been sent to the provided email address.',
        password_has_been_changed: 'Your password was successfully changed!',
        unsubscribed: 'You successfully unsubscribed!',
        agreeOnTerms: {
          text: 'I have read and agree with {link}!',
          link: 'terms and conditions of the e-shop and ticket insurance (p.5.7.)'
        },
        cart_is_empty: 'Cart is empty',
        enter_promo_code: 'Enter promo code',
        stripeDescription:
          'You have 10 minutes to complete your payment. Otherwise the transaction will be canceled and the tickets you have reserved will become freely available for purchase.',
        fdataDescription:
          'You have 10 minutes to complete your payment. Otherwise the transaction will be canceled and the tickets you have reserved will become freely available for purchase.',
        i_have_promocode: 'I have promo code',
        promocode_activated: 'Promocode activated!',
        promocode_apply_fail: 'Failed to apply promocode',
        newsAndOffers: 'Do you want to receive news and great ticket deals?',
        spam: 'I want to receive information about events by email',
        purchaseAsLegal: 'Purchase as legal person',
        swedDescription:
          '<b>This payment type is available only for Swedbank Latvia customers.</b> You have 10 minutes to complete your payment. Otherwise the transaction will be cancelled and the tickets you have reserved will become freely available for purchase.',
        payment_failed_w_error: 'Payment failed with error code',
        invoice_copy_sent:
          'A copy of tickets and invoice has been sent to your e-mail address:',
        invoice_copy_will_be_sent:
          'In a moment, the tickets will be sent to your specified email.',
        user_exists_1: 'User with this email already exists. Please ',
        user_exists_2: 'login',
        user_exists_3: ' or ',
        user_exists_4: 'restore password',
        something_wrong: 'Something went really wrong',
        page_not_found: 'Page not found',
        no_events_available: 'No events available',
        order_completed_1: 'Your order',
        order_completed_2: 'for',
        order_completed_3: 'is completed',
        processing_tickets: 'Processing tickets',
        choose_payment_country:
          "Please choose which country's banks / payment systems to use."
      },
      titles: {
        agreement: 'Agreement',
        buyer: 'Buyer',
        paymentMethod: 'Payment method',
        purchased_tickets: 'Download your tickets',
        your_tickets: 'Your tickets'
      },
      filters: {
        all_dates: 'All dates',
        all_categories: 'All categories',
        category_selected: 'category selected',
        pick_category: 'Pick category',
        all_locations: 'All locations',
        pick_country: 'Select country',
        location_selected: 'location selected',
        pick_location: 'Pick location',
        pick_another_date: 'Choose another date'
      },
      fdata: {
        100: 'Decline (general, no comments)',
        101: 'Decline, expired card',
        102: 'Decline, suspected fraud',
        104: 'Decline, restricted card',
        105: "Decline, card acceptor call acquirer's security department",
        106: 'Decline, allowable PIN tries exceeded',
        111: 'Decline, invalid card number',
        116: 'Decline, no card record',
        118: 'Decline, not sufficient funds',
        119: 'Decline, transaction not permitted to cardholder',
        120: 'Decline, transaction not permitted to terminal',
        121: 'Decline, exceeds withdrawal amount limit',
        122: 'Decline, security violation',
        123: 'Decline, exceeds withdrawal frequency limit',
        129: 'Decline, suspected counterfeit card',
        204: 'Pick-up, restricted card',
        208: 'Pick-up, lost card',
        902: 'Decline reason message: invalid transaction',
        904: 'Decline reason message: format error',
        908: 'Decline reason message: transaction destination cannot be found for routing',
        909: 'Decline reason message: system malfunction',
        911: 'Decline reason message: card issuer timed out',
        912: 'Decline reason message: card issuer unavailable',
        940: 'Decline, blocked by fraud filter'
      },
      eventLabels: {
        postponed: 'New date is coming soon!',
        suspended: 'Sales of this event is stopped till future notice.',
        preorder:
          'Presale starts at <b class="color--text-blue wsnw">{from}</b>',
        public_preorder:
          'Public presale starts at <b class="color--text-blue wsnw">{from}</b>'
      },
      eventLabelsButton: {
        preorder: 'Buy after {0}',
        onlinesaleended: 'Online sales are ended'
      },
      countdown: {
        days: 'd.',
        hours: 'h.',
        minutes: 'min.',
        seconds: 's.'
      },
      presale: {
        early_access: 'Early access presale',
        early_access_tooltip:
          'If you have a special presale code, you can buy tickets for this event before the public sale starts. Presale codes are distributed by the artist and/or event organizer. Ticketshop does not have these codes available.'
      },
      insurance: {
        available: 'Ticket insurance available',
        tickets: 'Ticket insurance',
        title: 'BE SAFE AND CHOOSE TICKET INSURANCE!',
        description:
          'Losses will be covered if You have an accident or are unable to attend the event due to health-related restrictions, or in case of event cancellation / rescheduling, if the new changes do not suit you at all.',
        details: 'More details about the insurance terms and conditions.',
        secure_tickets: 'Insure the tickets',
        continue_without: 'Continue without insurance',
        cost: 'INSURANCE COST',
        secure_order: 'Insure purchase',
        cancel: 'Cancel insurance',
        ticket_secured: 'Ticket is insured',
        ticket_unavailable: ' Insurance is not available',
        provider: 'BALCIA INSURANCE',
        info: {
          title: 'We will cover Your losses, if:',
          list: [
            "You've had a problem and You can't make it to the event;",
            'Covid-19 or other health restrictions interfere with plans to attend the event;',
            'The event is canceled or postponed and the new time or place does not suit You.'
          ]
        }
      },
      currencyModal: {
        title: 'YOU HAVE AN UNFINISHED PURCHASE IN {cartCurrency} CURRENCY',
        subtitle:
          'The event you want to choose tickets for is in {eventCurrency} currency. Currently, you have tickets in your cart that are priced in {cartCurrency} currency. You need to complete the existing purchase or clear the cart to make a purchase in {eventCurrency} currency.',
        finish_payment:
          'Complete the existing purchase in {cartCurrency} currency',
        clear_cart:
          'Clear the cart and make a new purchase in {eventCurrency} currency'
      },
      eventSubscribeModal: {
        title: 'Registration'
      },
      eventSubscribe: {
        registration: 'Registration',
        register: 'Register',
        earlyBird:
          'Register and get chance to purchase limited <b class="color--text-blue wsnw">Early bird tickets</b>'
      },
      refund: {
        title: 'REFUND for order Nr. <div>{number}</div>',
        details: 'Refund details',
        deadline: 'Refund request deadline till:',
        reason: 'Refund reason',
        info: 'Please note that refunds are made to the same bank account or payment card from which the original purchase was made.',
        payment: {
          type: 'Payment type',
          bank: 'Bank name',
          payer: 'Beneficiary name',
          account: 'Account No.'
        },
        order_tickets: 'Tickets in purchase',
        ticket_number: 'Ticket No.',
        ticket_type: 'Type',
        ticket_price: 'Price',
        ticket_venue: 'Venue fee',
        ticket_service: 'Service fee',
        flat_price: 'Ticket net price (incl. VAT)',
        total_refund: 'Total refund',
        initiate: 'Initiate refund',
        terms: 'I have read and agree with terms & conditions.',
        steps: {
          initiate: 'Refund initiated',
          process: 'Processing  refund',
          compete: 'Refund completed'
        },
        success: {
          title: 'Refund request successfully submitted',
          subtitle:
            'Your refund request will be processed shortly. You will receive a separate e-mail when Your refund is processed.'
        },
        payout: {
          title: 'Refund is in progress',
          subtitle:
            'Your refund request will be processed shortly. You will receive a separate e-mail when Your refund is processed.'
        },
        completed: {
          title: 'Refund completed',
          subtitle:
            'Your refund has been processed. Please see the refund e-mail for details.'
        },
        expired: {
          title: 'The deadline for refund requests has passed',
          subtitle:
            'At this time, the ticket seller is not authorized to issue a refund for this purchase after the specified number of days have passed.'
        }
      },
      ddr: {
        details: 'Request details',
        title: 'Data deletion request',
        subtitle: '',
        status: 'Status',
        comment: 'Comment',
        created_at: 'Created at',
        updated_at: 'Updated at'
      }
    })
  })
}
